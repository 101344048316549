<section class="page-content">
    <div class="row">
        <div class="header"> Recent Activity</div>
        <div class="column">
            <mat-table #table1 [dataSource]="eventHubData" class="scrollable-div">

                <ng-container matColumnDef="eventHubName">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>
                        Event Hub Name
                    </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row; let i = index;"
                        (click)="getEventSubject(row)"><a class="event-link">{{ row.EventHubName }}</a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastActivityTime">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>
                        Latest Activity
                    </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row"> {{row.ReportingPeriod | date: 'MM/dd/yyyy
                        hh:mm:ss a'}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns" [style.minHeight.px]="35"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [style.minHeight.px]="25"></mat-row>

            </mat-table>
        </div>

        <div class="column">
            <mat-table #table2 [dataSource]="eventsSubjectsList" class="scrollable-div2">

                <ng-container matColumnDef="eventSubject">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>
                        Event Subject
                    </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row; let i = index;"> {{row.EventSubject}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lasthour">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>
                        Last hour
                    </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row"> {{row.Occurencies}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="last3hour">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>
                        Last 3 hours
                    </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row">  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastday">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>
                        Last day
                    </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row">  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsTable2" [style.minHeight.px]="35"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsTable2;" [style.minHeight.px]="25"></mat-row>

            </mat-table>
        </div>
    </div>

    <!-- <div #scrollableDiv3 class="scrollable-div"> -->
        <div class="row">
        <div class="header">Recent Processing Failures</div>
        <div class="column2">
        <mat-table #table3 [dataSource]="eventProcessingFailure" class="scrollable-div3">
            <ng-container matColumnDef="date">
                <mat-header-cell class='min-width-l' *matHeaderCellDef>
                    Date
                </mat-header-cell>
                <mat-cell class='min-width-l' *matCellDef="let row; let i = index;">
                    {{row.FailedEventHeader.TimeStamp | date: 'MM/dd/yyyy' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="time">
                <mat-header-cell class='min-width-l' *matHeaderCellDef>
                    Time
                </mat-header-cell>
                <mat-cell class='min-width-l' *matCellDef="let row; let i = index;">
                    
                    {{row.FailedEventHeader.TimeStamp | date: 'hh:mm:ss a'}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="eventHubName">
                <mat-header-cell class='min-width-l' *matHeaderCellDef>
                    Event Hub
                </mat-header-cell>
                <mat-cell class='min-width-l' *matCellDef="let row; let i = index;">
                    {{row.EventHubName}}
                    
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="producerName">
                <mat-header-cell class='min-width-l' *matHeaderCellDef>
                    Producer
                </mat-header-cell>
                <mat-cell class='min-width-l' *matCellDef="let row; let i = index;">
                   
                    {{row.FailedEventHeader.ProducerName}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="eventSubject">
                <mat-header-cell class='min-width-l' *matHeaderCellDef>
                    Event Subject
                </mat-header-cell>
                <mat-cell class='min-width-l' *matCellDef="let row; let i = index;"> {{row.FailedEventHeader.EventSubject}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastActivityTime">
                <mat-header-cell class='min-width-l' *matHeaderCellDef>
                    Error Message
                </mat-header-cell>
                <mat-cell class='min-width-l' *matCellDef="let row"> {{row.FailureDetails.ErrorMessage}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsTable3" [style.minHeight.px]="35"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsTable3;" [style.minHeight.px]="25"></mat-row>
        </mat-table>
    </div>
    </div>
</section>