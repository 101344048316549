import { Component, OnInit, ViewChild } from '@angular/core';
import { ServerSentEventMonitoringService } from 'app/services/sse/serverSentEventMonitoring.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MatLegacyTableDataSource, MatLegacyTable } from '@angular/material/legacy-table';
import { Router, NavigationEnd } from '@angular/router';
import { EventSubjectStatus } from 'app/types/enterprise-events-models/event-subject-status.model';
import { EventHubStatus } from 'app/types/enterprise-events-models/event-hub-status.model';
import { EventProcessingFailure } from 'app/types/enterprise-events-models/event-processing-failure.model';

@Component({
  selector: 'events-monitoring',
  templateUrl: './events-monitoring.component.html',
  styleUrls: ['./events-monitoring.component.scss']
})

export class EventsMonitoringComponent implements OnInit {
  rowCount = 0;
  currentPage = 1;
  pageSize = 10;
  sortColumn = "CreatedDate";
  sortOrder = "desc";
  items: string[] = [];
  @ViewChild(MatLegacyTable) table1: MatLegacyTable<EventHubStatus>;
  @ViewChild('table2') table2: MatLegacyTable<EventHubStatus>;
  @ViewChild('table3') table3: MatLegacyTable<EventHubStatus>;

  displayedColumns = [
    'eventHubName',
    'lastActivityTime'
  ];

  displayedColumnsTable2 = [
    'eventSubject',
    'lasthour',
    'last3hour',
    'lastday'
  ];

  displayedColumnsTable3 = [
    'date',
    'time',
    'eventHubName',
    'producerName',
    'eventSubject',
    'lastActivityTime'
  ];

  private subscription: Subscription | null = null;
  dataSource3: MatLegacyTableDataSource<EventHubStatus>;
  dataSource1: any[] = [];
  eventHubData: any[] = [];
  eventsSubjectsList: EventSubjectStatus[] = [];
  eventProcessingFailure: EventProcessingFailure[] = [];

  eventlist: EventHubStatus[];

  constructor(
    private serverSentEventMonitoringService: ServerSentEventMonitoringService,
    private router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.cleanUp(); // Call cleanup method on navigation
      }
    });
  }

  ngOnInit() {
    const url = environment.enterpriseEventsApiUrl;
    const options: EventSourceInit = { withCredentials: false };
    const eventNames: string[] = ['ping'];
    this.subscription = this.serverSentEventMonitoringService.connectToServerSentEvents(url, options, eventNames)
      .subscribe({
        next: enterpriseEventData => {
          // Store the incoming event data in memory
          this.serverSentEventMonitoringService.setSseDataToList(enterpriseEventData);
          const eventDetails = this.serverSentEventMonitoringService.getSseDataFromList();
          this.eventlist = eventDetails;
          this.updateDataSources(eventDetails);
        },
        error: error => {
          console.error('SSE Error:', error);
        }
      });
  }

  private cleanUp() {
    this.subscription?.unsubscribe();
    this.serverSentEventMonitoringService.closeConnection();
  }

  private updateDataSources(eventDetails: EventHubStatus[]) {
    this.eventHubData = eventDetails.reduce((acc, curr) => acc.concat(curr), []);

    this.eventProcessingFailure = this.eventHubData.flatMap(d => {
      return d.Failures.map(failure => ({
        ...failure,
        EventHubName: d.EventHubName
      }));
    });
    this.dataSource3 = new MatLegacyTableDataSource<EventHubStatus>(eventDetails);
    if (this.eventHubData.length > 0 || this.dataSource3.data.length > 0) {
      this.scrollToBottom();
    }
  }

  countEventsInLastHour(data: EventHubStatus, hours: number): number {
    if (hours <= 0) {
      hours = 1;
    }
    const oneHourAgo = new Date(Date.now() - hours * 60 * 60 * 1000);
    let count = 0;

    const reportingPeriod = new Date(data.ReportingPeriod);

    // Check if the reporting period is within the last hour
    if (reportingPeriod >= oneHourAgo) {
      for (const activity of data.Activity) {
        count += 1;// activity.Occurencies;
      }
    }
    return count;
  }


  public getEventSubject(data: any) {
    debugger;
    this.eventsSubjectsList = data.Activity;
    this.countEventsInLastHour(data, 1);
  }

  private scrollToBottom(): void {
    const table = document.querySelector('mat-table');
    table.scrollBy(0, table.scrollHeight);

    const table2 = document.querySelector('.scrollable-div2');
    table2.scrollBy(0, table2.scrollHeight);

    const table3 = document.querySelector('.scrollable-div3');
    table3.scrollBy(0, table3.scrollHeight);
  }
}
