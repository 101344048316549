export const environment = {
  production: true,
  debug: '______IsDebugMode______',
  ciamUrl: '______CiamUrl______/api/',
  patientPortalsUrl: '______PatientPortalsUrl______',
  facilitiesUrl: '______FacilitiesUrl______/api/',
  ciamOktaClientId: '______OktaClientId______',
  oktaLoginScreenBrand: '______OktaLoginScreenBrand______',
  idpId: '______OktaIdpId______',
  ciamOktaUrl: '______OktaUrl______',
  providerSearchApiUrl: '______ProviderSearchApiUrl______/api/',
  identityApiUrl: '______IdentityApiUrl______/api/',
  enterpriseEventsApiUrl:'______enterpriseEventsApiUrl______/sse',
  facilitiesApimSubscriptionKey: '______FacilitiesApimSubscriptionKey______',
  patientPortalsSubscriptionKey: '______PatientPortalsApimSubscriptionKey______',
  facilitiesApimUrl: '______FacilitiesApimUrl______',
  useHash: true,
  enrollmentsErrorNumberOfDays: Number('______EnrollmentsErrorNumberOfDays______'),
  renewTimeBeforeTokenExpiresInSeconds: Number('______RenewTimeBeforeTokenExpiresInSeconds______'),
  featureFlags: {
    general: {
      allowAllUsers: bool('______FeatureFlags_General_AllowAllUsers______')
    },
    navigation: {
      termsAndConditions: {
        list: bool('______FeatureFlags_Navigation_TermsAndConditions_List______'),
        create: bool('______FeatureFlags_Navigation_TermsAndConditions_Create______'),
        view: bool('______FeatureFlags_Navigation_TermsAndConditions_View______')
      },
      instructions: {
        create: bool('______FeatureFlags_Navigation_Instructions_Create______')
      },      
      patientPortals: {
        cerner: {
          invitations: {
            list: bool('______FeatureFlags_Navigation_PatientPortals_Cerner_Invitations_List______')
          }
        }
      },
      logs: {
        search: bool('______FeatureFlags_Navigation_Logs_Search______'),
        identity:{
          list:bool('______FeatureFlags_Navigation_Identity_Audit_Logs_List______'),
          view:bool('______FeatureFlags_Navigation_Identity_Audit_Logs_View______')
        },
        enrollments: {
          list: bool('______FeatureFlags_Navigation_Logs_Enrollments_List______'),
          view: bool('______FeatureFlags_Navigation_Logs_Enrollments_View______')
        }
      },
      users: {
        search: bool('______FeatureFlags_Navigation_Users_List______'),
        dignityhealth:{
          view:bool('______FeatureFlags_Navigation_DignityHealth_Users_List______')
        },
        patientportals:{
          list:bool('______FeatureFlags_Navigation_PatientPortals_Users_List______'),
          view:bool('______FeatureFlags_Navigation_PatientPortals_Users_View______')
        },
        cernermillenium:
        {
          list:bool('______FeatureFlags_Navigation_Cerner_Users_List______'),
          view:bool('______FeatureFlags_Navigation_Cerner_Users_View______')
        },
        identity:{
          list:bool('______FeatureFlags_Navigation_Identity_Users_List______'),
          view:bool('______FeatureFlags_Navigation_Identity_Users_View______'),
        },
        view: bool('______FeatureFlags_Navigation_Users_View______')
      },
      entities: {
        facilities:bool('______FeatureFlags_Navigation_Entities_Facilities______'),
        bargroup: bool('______FeatureFlags_Navigation_Entities_BarGroup______'),
        region: bool('______FeatureFlags_Navigation_Entities_Region______'),        
        empihub:  bool('______FeatureFlags_Navigation_Entities_EMPI_Hub______'),
        bargroupFacilities: {
          create: bool('______FeatureFlags_Navigation_Entities_BargroupFacilities_Create______')
        }
      },
      azureSearch: {
        view: bool('______FeatureFlags_Navigation_AzureSearch_View______'),
        providerSearch: {
          view: bool('______FeatureFlags_Navigation_AzureSearch_ProviderSearch_View______')
        },
        scoreAndFields: {
          create: bool('______FeatureFlags_Navigation_AzureSearch_ScoreAndFields_Create______')
        }
      },
      siteSettings: {
        view: bool('______FeatureFlags_Navigation_SiteSettings_View______'),
        domainWhitelisting: {
          create: bool('______FeatureFlags_Navigation_SiteSettings_DomainWhitelisting_Create______')
        },
        alerts: {
          create: bool('______FeatureFlags_Navigation_SiteSettings_Alerts_Create______')
        }
      },
      facilitiesVisit: {
        view: bool('______FeatureFlags_Navigation_FacilitiesVisit_View______')
      },
      developersOptions: {
        view: bool('______FeatureFlags_Navigation_DevelopersOptions_View______'),
        cernerSSODashboard: {
          view: bool('______FeatureFlags_Navigation_DevelopersOptions_CernerSSODashboard_View______')
        }
      },
      enterpriseEvents: {
        view: bool('______FeatureFlags_Navigation_EnterpriseEvents_View______'),
        monitoring: {
          view: bool('______FeatureFlags_Navigation_EnterpriseEvents_Monitoring_View______'),
        },
        replayEngine: {
          view: bool('______FeatureFlags_Navigation_EnterpriseEvents_ReplayEngine_View______')
        }
      }
    }
  },
  factorType: '______factorType______',
  oktaBaseUrl: '______oktaBaseUrl______',
  domainNameMetadataServerUrl:'______helloHumanKindnessSTSServerUrl______',
  envName: '______EnvName______'
};

function bool(expression: string): boolean {
  return expression == 'true';
}
