import { EventSubjectActivityData } from '../activity-monitoring-intake/event-subject-activity-data.model';
import { EventActivityItem } from './event-activity-item.model';

export class ActivityRetentionBucket {
  bucketTimeSpan: number; // TimeSpan in milliseconds
  longestBucketFlag: boolean;
  oldestIncludedIndex: number = -1;
  sumOfOccurencies: number = 0;
  sumOfSuccessResponses: number = 0;
  sumOfFailureResponses: number = 0;
  subjectActivities: EventActivityItem[] = [];

  constructor(bucketTimeSpan: number, longestBucketFlag: boolean) {
    this.bucketTimeSpan = bucketTimeSpan;
    this.longestBucketFlag = longestBucketFlag;
  }

  addActivityItem(activityItem: EventSubjectActivityData): void {
    this.oldestIncludedIndex++;
    this.sumOfOccurencies += activityItem.Occurencies;
    this.sumOfSuccessResponses += activityItem.SuccessResponses;
    this.sumOfFailureResponses += activityItem.FailureResponses;
  }

  removeActivityItem(activityItem: EventActivityItem): void {
    this.oldestIncludedIndex--;
    this.sumOfOccurencies -= activityItem.occurencies;
    this.sumOfSuccessResponses -= activityItem.successResponses;
    this.sumOfFailureResponses -= activityItem.failureResponses;
  }
}
