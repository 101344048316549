import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EnterpriseEventsActivityMonitoringService } from 'app/services/sse/enterprise-events-activity-monitoring.service';
import { environment } from 'environments/environment';
import { Subscription, timer } from 'rxjs';
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { Router, NavigationEnd } from '@angular/router';
import { EventHubActivityData } from 'app/types/enterprise-events/activity-monitoring-intake/event-hub-activity-data.model';
import { EventHubActivityStatus, EventFailures } from 'app/types/enterprise-events/activity-monitoring-representation/event-hub-activity-status.model';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'events-monitoring',
  templateUrl: './events-monitoring.component.html',
  styleUrls: ['./events-monitoring.component.scss']
})
export class EventsMonitoringComponent implements OnInit, OnDestroy {
  @ViewChild('table2') table2: MatLegacyTable<EventHubActivityData>;
  @ViewChild('table3') table3: MatLegacyTable<EventFailures>;

  displayedColumns = ['eventHubName', 'lastActivityTime'];
  displayedColumnsTable2 = ['eventSubject', 'lasthour', 'last3hour', 'lastday'];
  displayedColumnsTable3 = ['date', 'time', 'eventHubName', 'producerName', 'eventSubject', 'lastActivityTime'];

  private subscription: Subscription | null = null;
  eventHubData: MatLegacyTableDataSource<EventHubActivityStatus>;
  FailData: MatLegacyTableDataSource<EventFailures>;
  eventHubActivityStatus: EventHubActivityStatus[] = [];
  eventsSubjectsList: any[] = [];
  eventProcessingFailure: EventFailures[] = [];
  eventHubName: string = ""; 

  constructor(
    private enterpriseEventsActivityMonitoringService: EnterpriseEventsActivityMonitoringService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { }
    });
  }

  ngOnInit() {
    this.subscription = timer(0, 60000).subscribe(() => {
      this.enterpriseEventsActivityMonitoringService.processTimer(new Date());
    });

    this.eventHubActivityStatus = this.enterpriseEventsActivityMonitoringService.getEventHubDataFromList();
    if (this.eventHubActivityStatus.length > 0) {
      this.updateDataSources();
    }
    else {
      const url = environment.enterpriseEventsApiUrl;
      const options: EventSourceInit = { withCredentials: false };
      const eventNames: string[] = ['ping'];

      this.subscription = this.enterpriseEventsActivityMonitoringService.connectToServerSentEvents(url, options, eventNames)
        .subscribe({
          next: (enterpriseEventData) => {
            this.enterpriseEventsActivityMonitoringService.processActivityData(enterpriseEventData);
            this.updateDataSources();
          },
          error: (error) => {
            console.error('SSE Error:', error);
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getEventSubject(data: any) {
    this.eventHubName = data.eventHubName;
    this.eventsSubjectsList = data.activity;
  }

  private updateDataSources() {
    this.enterpriseEventsActivityMonitoringService.getEventHubDataFailuresList().subscribe(failureList => {
      this.eventProcessingFailure = failureList;
    });
    this.changeDetectorRef.detectChanges();
    this.table3.renderRows();
    this.eventHubData = new MatLegacyTableDataSource<EventHubActivityStatus>(<EventHubActivityStatus[]>this.eventHubActivityStatus);
  }

  private scrollToBottom(): void {
    const table = document.querySelector('mat-table');
    table.scrollBy(0, table.scrollHeight);

    const table2 = document.querySelector('.scrollable-div2');
    table2.scrollBy(0, table2.scrollHeight);

    const table3 = document.querySelector('.scrollable-div3');
    table3.scrollBy(0, table3.scrollHeight);
  }

  private cleanUp() {
    this.subscription?.unsubscribe();
    this.enterpriseEventsActivityMonitoringService.closeConnection();
  }
}