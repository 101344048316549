import { EventSubjectActivityStatus } from './event-subject-activity-status.model';
import { EventHeader } from "../public-library/event-header.model";
import { ProcessingFailureDetails } from "../public-library/processing-failure-details.model";

export class EventHubActivityStatus {
    eventHubName: string;
    lastestActivityDate: string;
    activity: EventSubjectActivityStatus[] = [];
}

export class EventFailures {
    eventHubName: string;
    FailedEventHeader: EventHeader;
    FailureDetails: ProcessingFailureDetails;
}
