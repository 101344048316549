import { EventSubjectActivityData } from '../activity-monitoring-intake/event-subject-activity-data.model';

export class EventActivityItem {
  activityTime: Date;
  occurencies: number;
  successResponses: number;
  failureResponses: number;

  constructor(activityTime: Date, status: EventSubjectActivityData) {
    this.activityTime = activityTime;
    this.occurencies = status.Occurencies;
    this.successResponses = status.SuccessResponses;
    this.failureResponses = status.FailureResponses;
  }
}
