import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { EventHubActivityStatus, EventFailures } from 'app/types/enterprise-events/activity-monitoring-representation/event-hub-activity-status.model';
import { EventSubjectActivityStatus } from 'app/types/enterprise-events/activity-monitoring-representation/event-subject-activity-status.model';
import { EventActivityItem } from 'app/types/enterprise-events/activity-monitoring-representation/event-activity-item.model';

@Injectable({
  providedIn: 'root', // Ensures singleton service
})
export class EnterpriseEventsActivityMonitoringService {
  private eventSource: EventSource | null = null;
  private eventSubject = new Subject<any>();
  private eventNames: string[] = [];
  enterpriseEventsList: EventHubActivityStatus[] = [];
  eventProcessingFailureList: EventFailures[] = [];
  private eventProcessingFailureListSubject = new BehaviorSubject<EventFailures[]>([]);

  constructor(private zone: NgZone) { }

  getEventSource(url: string, options: EventSourceInit): EventSource {
    return new EventSource(url, options);
  }

  connectToServerSentEvents(url: string, options: EventSourceInit, eventNames: string[] = []): Observable<any> {
    // Only create a new EventSource if one doesn't already exist
    if (!this.eventSource) {
      this.eventSource = new EventSource(url, options);
      this.eventNames = eventNames;
      this.eventSource.onerror = (error) => {
        this.zone.run(() => this.eventSubject.error(new Error(`SSE Error: ${error}`)));
      };

      // Set up event listeners for each event name
      this.eventNames.forEach(event => {
        this.eventSource!.addEventListener(event, (data: MessageEvent) => {
          const eventData = JSON.parse(data.data);
          this.zone.run(() => this.eventSubject.next(eventData));
        });
      });
    }
    return this.eventSubject.asObservable();
  }

  closeConnection() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  setEvenHubDataToList(eventHubActivityStatus: EventHubActivityStatus) {
    if (this.enterpriseEventsList.length > 0) {
      this.enterpriseEventsList = this.enterpriseEventsList.filter(d => d.eventHubName != eventHubActivityStatus.eventHubName);
    }
    this.enterpriseEventsList.push(eventHubActivityStatus);
  }

  getEventHubDataFromList(): EventHubActivityStatus[] {
    return this.enterpriseEventsList;
  }

  getEventHubDataFailuresList(): Observable<EventFailures[]> {
    return this.eventProcessingFailureListSubject.asObservable();
  }

  setEventHubDataFailuresList(eventFailure: EventFailures): void {
    const currentList = this.eventProcessingFailureListSubject.getValue();
    this.eventProcessingFailureListSubject.next([...currentList, eventFailure]);
  }

  removeEventHubDataFromList() {
    this.enterpriseEventsList.pop();
  }

  processActivityData(enterpriseEventData: any) {
    //debugger;
    var newEventDetails = new EventHubActivityStatus();
    if (enterpriseEventData.length > 0) {
      // loop for all events received from sse
      enterpriseEventData.forEach((eventData) => {
        //debugger;
        if (eventData.Failures.length > 0 && this.eventProcessingFailureListSubject.getValue().length < 100) {
          eventData.Failures.forEach(failure => {
            this.setEventHubDataFailuresList({
              eventHubName: eventData.EventHubName,
              FailedEventHeader: failure.FailedEventHeader,
              FailureDetails: failure.FailureDetails
            });
          });
        }
        //debugger;
        if (this.enterpriseEventsList.length > 0) {
          //debugger;
          // check if event hub record based on event hub name already existing in main list
          var existedAnyEventHubAlready = this.enterpriseEventsList.filter(d => d.eventHubName == eventData.EventHubName);
          if (existedAnyEventHubAlready.length > 0) {
            //debugger;
            // loop through all existed event hubs to update the buckets total OR to add the new subjects 
            existedAnyEventHubAlready.forEach((eventHubActivity) => {
              //debugger;
              newEventDetails.eventHubName = eventHubActivity.eventHubName;
              newEventDetails.lastestActivityDate = eventData.ReportingPeriod;
              eventHubActivity.lastestActivityDate = eventData.ReportingPeriod;
              // loop through all activities coming from new event from sse
              eventData.Activity.forEach((actData) => {
                //debugger;
                // check if subject already exists in event hub activity from the main list
                var existingActivitySubjectsInEventHub = eventHubActivity.activity.filter(d => d.eventSubject == actData.EventSubject);
                // if subject already exists in event hub then update the buckets   
                if (existingActivitySubjectsInEventHub.length > 0) {
                  // loop through each existing activity to update retension Bucket and subjectActivity
                  existingActivitySubjectsInEventHub.forEach((activity) => {
                    //debugger;
                    var newActivity = new EventSubjectActivityStatus(activity.eventSubject);
                    const newEventActivityItem = new EventActivityItem(eventData.ReportingPeriod, actData);
                    // added new activityItem on top of exiting subjectActivity List 
                    //activity.subjectActivity.unshift(newEventActivityItem);
                    activity.retentionBuckets.forEach((retentionBucket) => {
                      //debugger;
                      retentionBucket.subjectActivities.unshift(newEventActivityItem);
                      retentionBucket.addActivityItem(actData);
                    })
                    //debugger;
                    newActivity.retentionBuckets = activity.retentionBuckets;
                    newActivity.subjectActivity = activity.subjectActivity;
                    newEventDetails.activity.unshift(newActivity);
                  })
                }
                else {
                  // add new event subject activity status in existing event hub record where subject is not exiting earlier i.e subjectName not matched
                  //debugger;
                  var newEventSubjectActivity = new EventSubjectActivityStatus(actData);
                  newEventSubjectActivity.eventSubject = actData.EventSubject;
                   // add new eventActivity item and update retentionBucket in the subject
                  const newEventActivityItem = new EventActivityItem(eventData.ReportingPeriod, actData);
                  newEventSubjectActivity.retentionBuckets.forEach((retentionBucket) => {
                    //debugger;
                    retentionBucket.subjectActivities.unshift(newEventActivityItem);
                    retentionBucket.addActivityItem(actData);
                  })
                  this.enterpriseEventsList.filter(d => d.eventHubName == newEventDetails.eventHubName)
                    .forEach((hub) => {
                      //debugger;
                      newEventDetails.activity.unshift(newEventSubjectActivity);
                    })
                }
              })
            })
          }
          else {
            // Add new event hub record if does not exist in the current eventHubActivityStatus list
            //debugger;
            this.addNewEventRecord(eventData);
          }
        }
        else {
          // Add new event hub record in list eventHubActivityStatus when there is no record in the list.
          //debugger;
          this.addNewEventRecord(eventData);
        }
      })
    }
  }

  private addNewEventRecord(eventData: any) {
    var newEvent = new EventHubActivityStatus();
    newEvent.eventHubName = eventData.EventHubName;
    eventData.Activity.forEach((activity) => {
      var newEventSubjectActivity = new EventSubjectActivityStatus(activity.EventSubject);
      const newEventActivityItem = new EventActivityItem(eventData.ReportingPeriod, activity);
      newEventSubjectActivity.retentionBuckets.forEach((retentionBucket) => {
        retentionBucket.subjectActivities.unshift(newEventActivityItem); // added in new property inside bucket
        retentionBucket.addActivityItem(activity);
      });
      newEvent.activity.unshift(newEventSubjectActivity);
    });
    newEvent.lastestActivityDate = eventData.ReportingPeriod;
    this.enterpriseEventsList.unshift(newEvent);
  }

  processTimer(currentTime: Date): boolean {
    let somethingGotUpdated = false;
    //debugger;
    this.enterpriseEventsList.forEach(eventHubStatus => {
      //debugger;
      eventHubStatus.activity.forEach(eventSubjectStatus => {
        eventSubjectStatus.retentionBuckets.forEach(retentionBucket => {
          //debugger;
          const removeItemsOlderThan = new Date(currentTime.getTime() - retentionBucket.bucketTimeSpan);
          retentionBucket.subjectActivities.forEach(subAct => {
            const activityTime = new Date(subAct.activityTime);
            if (removeItemsOlderThan >= activityTime && retentionBucket.oldestIncludedIndex >= 0) {
              retentionBucket.removeActivityItem(subAct);
              retentionBucket.subjectActivities.pop();
            }
            somethingGotUpdated = true;
          })
        });
      });
    });
    return somethingGotUpdated;
  }
}