import { HttpClient } from '@angular/common/http';
import { httpLoaderFactory } from './auth/angular-oauth-configuration';
import { AuthModule, StsConfigHttpLoader, StsConfigLoader, LogLevel, OidcSecurityService, AbstractSecurityStorage, DefaultLocalStorageService } from 'angular-auth-oidc-client';
import { ActivePipe } from './pipes/active/active.pipe';
import { AlertSettingsComponent } from './components/alerts/alert-settings/alert-settings.component';
import { AlertSettingUpdateComponent } from './components/alerts/alert-setting-update/alert-setting-update.component';
import { AngularSplitModule } from 'angular-split';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppDateAdapter } from 'app/common/app-date-adapter';
import { AppSharedModule } from './shared/shared.module';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { AzureSearchService } from './services/azure-search/azure-search-service';
import { BarGroupFacilitiesComponent } from './components/bar-group/bargroup-facilities/bargroup-facilities.component';
import { BarGroupsComponent } from './components/bar-group/bar-groups/bar-groups.component';
import { BarGroupsCreateUpdateComponent } from './components/bar-group/bar-groups-create-update/bar-groups-create-update.component';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { CernerEnvironmentConfigsComponent } from './components/cerner-environment-configs/cerner-environment-configs.component';
import { CernerInvitationsComponent } from './components/cerner/cerner-invitations/cerner-invitations.component';
import { PatientInvitationDetailInformationComponent } from './components/cerner/cerner-invitations/patient-invitation-detail-information.component';
import { CernerSSOConfigService } from './services/cerner-sso-config/cerner-sso-config';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { ciamAuthFactory } from './app.factories';
import { CommonModule } from '@angular/common';
//import { configureAuth } from './auth/angular-oauth-configuration';
import { CustomRouteReuseStrategy } from 'app/route-reuse-strategy.component';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DomainWhitelistingComponent } from './components/domain/domain-whitelisting/domain-whitelisting.component';
import { EmpiHubComponent } from './components/empi/empi-hub/empi-hub.component';
import { EmpiHubCreateUpdateComponent } from './components/empi/empi-hub-create-update/empi-hub-create-update.component';
import { EnumKeyValueListPipe } from './pipes/enum-key-value-list/enum-key-value-list.pipe';
import { environment } from 'environments/environment';
import { ExpireModalComponent } from './components/expire-modal/expire-modal.component';
import { FacilitiesComponent } from './components/facility/facilities/facilities.component';
import { FacilitiesCreateUpdateComponent } from './components/facility/facilities-create-update/facilities-create-update.component';
import { FacilitiesService } from './services/facilities/facilities-service';
import {
  facility,
  parentUser,
  terms,
  user,
  userSearch
} from './store/reducers';
import { FacilityAddressPipe } from './pipes/facility-address/facility-address.pipe';
import { FacilityDomainAssociationComponent } from './components/facility/facility-domain-association/facility-domain-association.component';
import { FilterUserLevelPipe } from './pipes/filterLevel/filterLevel';
import { FlagGuard } from './guards/flag-guard/flag-guard.guard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormInputComponent } from './components/form-control/form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { info } from './store/reducers/info';
import { IdentityAuditLogsComponent } from './components/identity-audit-logs/identity-audit-logs.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { InstructionService } from 'app/services/instructions/instructions-service';
import { LoginComponent } from './components/auth/login/login.component';
import { LoginNavbarComponent } from './components/auth/login-navbar/login-navbar.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MaterialFormInputComponent } from './components/form-control/material-form-input/material-form-input.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OktaSupportGroupsService } from './services/okta-group/okta-support-group.service';
import { PatientEnrollmentErrorsComponent } from './components/patient/patient-enrollment-errors/patient-enrollment-errors.component';
import { PatientEnrollmentsComponent } from './components/patient/patient-enrollments/patient-enrollments.component';
import { PatientEnrollmentsSearchComponent } from './components/patient/patient-enrollments-search/patient-enrollments-search.component';
import { PatientFacilitiesVisitComponent } from './components/patient/patient-facilities-visit/patient-facilities-visit.component';
import { PatientPortalsInvitationDataSource } from 'app/types/patient-portals-invitation-datasource';
import { PatientsComponent } from './components/patient/patients/patients.component';
import { PatientsSearchComponent } from './components/patient/patients-search/patients-search.component';
import { PhoneNumberPipe } from './pipes/phone-number/phone-number.pipe';
import { PortalAccessService } from './services/portal-access/portal-access';
import { PortalLogViewerService } from 'app/services/portal-logs/portal-log-viewer';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RegionsComponent } from './components/region/regions/regions.component';
import { RegionsDetailsComponent } from './components/region/regions-details/regions-details.component';
import { ResendModalComponent } from './components/auth/resend-modal/resend-modal.component';
import { ResetOptionsDialogComponent } from './components/reset-options-dialog/reset-options-dialog.component';
import { ResetUserPasswordComponent } from './components/auth/reset-user-password/reset-user-password.component';
import { ResetUserPasswordDialogComponent } from './components/auth/reset-user-password/reset-user-password-dialog.component';
import { ResetUserPasswordStatusComponent } from './components/auth/reset-user-password/reset-user-password-status.component';
import { RouteDisabledComponent } from './components/route-disabled/route-disabled.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { ScoreAndFieldsService } from './services/azure-search/score-and-fields-service';
import { ScoreComponent } from './components/provider-search/scores/score.component';
import { SearchTypeComponent } from './components/search-type/search-type.component';
import { SiteSettingService } from './services/site-settings/site-settings-service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { SupportLogViewerComponent } from './components/support-log-viewer/support-log-viewer.component';
import { SynonymsComponent } from './components/provider-search/synonyms/synonyms.component';
import { TemporaryUserPasswordDialogComponent } from './components/auth/temporary-user-password/temporary-user-password-dialog.component';
import { TemporaryUserPasswordStatusComponent } from './components/auth/temporary-user-password/temporary-user-password-status.component';
import { TermsAndConditionsDataSource } from './types';
import { UiService } from 'app/services/ui/ui.service';
import { UiServiceErrorsDialogComponent } from './services/ui/ui.service';
import { UnauthorizedComponent } from './components/auth/unauthorized/unauthorized.component';
import { UpdateUsernameComponent } from './components/user/update-username/update-username.component';
import { UserAppointmentsComponent } from './components/user/user-appointments/user-appointments.component';
import { UserAssociatedAccountProfileComponent } from 'app/components/user/user-associated-account-profile/user-associated-account-profile.component';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { UserManageAssociatedAccountComponent } from 'app/components/user/user-manage-associated-account/user-manage-associated-account.component';
import { UserPatientDependentsDetailComponent } from './components/user/user-patient-dependents-detail/user-patient-dependents-detail.component';
import { UserProfileGroupsComponent } from './components/user/user-profile-groups/user-profile-groups.component';
import { UserProfileImageSectionComponent } from './components/user/user-profile-image-section/user-profile-image-section.component';
import { UserProfileOverviewComponent } from './components/user/user-profile-overview/user-profile-overview.component';
import { UserPropertiesComponent } from './components/user/user-properties/user-properties.component';
import { UsersAndPatientsComponent } from './components/user/users-and-patients/users-and-patients.component';
import { IdentityApiUsersComponent } from './components/user/identity-api-user/identity-api-user.component';
import { CreateUpdateIdentityApiUsersComponent } from './components/user/create-update-identity-api-user/identity-api-user/create-update-identity-api-user.component';
import { UsersComponent } from './components/user/users/users.component';
import { UsersDataSource } from 'app/types/users-datasource';
import { UsersSearchComponent } from './components/user/users-search/users-search.component';
import { UtcDatePipe } from './pipes/utc-date/utc-date.pipe';
import { OktaApiService } from './services/okta/okta-api.service';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { CheckUserAuthService } from './services/session/checkUserAuth-service';
import { LoginLoaderComponent } from './components/login-loader/login-loader.component';
import { EventsMonitoringComponent } from './components/enterprise-events/events-monitoring/events-monitoring.component';
import { ReplayEngineComponent } from './components/enterprise-events/replay-engine/replay-engine.component';
import { IdentityService } from './services/identity-logs/identity-service';
import { ThemesService } from './services/themes/themes-service';
import { SharedSearchService } from './services/shared-search/sharedSearch.service';
import { ServerSentEventMonitoringService } from './services/sse/serverSentEventMonitoring.service';

const flags = environment.featureFlags;
function defaultRoute() {
  return '/login-loader';
}

export function displayEnvironmentName(envName: string): string {
  envName = envName.toLowerCase();
  if (envName.includes('dev')) {
    return 'dev';
  }
  else if (envName.includes('qa1')) {
    return 'qa';
  }
  else if (envName.includes('stg')) {
    return 'stg';
  }
  else if (envName.includes('prd')) {
    return 'prd';
  }
  else {
    return '';
  }
}

@NgModule({
  declarations: [
    // Components
    AlertSettingsComponent,
    AlertSettingUpdateComponent,
    AppComponent,
    CernerInvitationsComponent,
    CernerEnvironmentConfigsComponent,
    DomainWhitelistingComponent,
    EmpiHubComponent,
    EmpiHubCreateUpdateComponent,
    ExpireModalComponent,
    FacilitiesComponent,
    FacilitiesCreateUpdateComponent,
    FacilityDomainAssociationComponent,
    FormInputComponent,
    HomeComponent,
    IdentityAuditLogsComponent,
    IdentityApiUsersComponent,
    CreateUpdateIdentityApiUsersComponent,
    InstructionsComponent,
    LoginComponent,
    LoginNavbarComponent,
    UserManageAssociatedAccountComponent,
    MaterialFormInputComponent,
    NotFoundComponent,
    ResendModalComponent,
    ResetUserPasswordComponent,
    RouteDisabledComponent,
    ResetUserPasswordStatusComponent,
    ResetUserPasswordDialogComponent,
    RegionsComponent,
    RegionsDetailsComponent,
    SearchTypeComponent,
    SupportLogViewerComponent,
    TemporaryUserPasswordStatusComponent,
    TemporaryUserPasswordDialogComponent,
    UiServiceErrorsDialogComponent,
    UsersComponent,
    UserDetailsComponent,
    UserAssociatedAccountProfileComponent,
    UserProfileImageSectionComponent,
    UserProfileOverviewComponent,
    UserProfileGroupsComponent,
    UsersSearchComponent,
    UnauthorizedComponent,
    ProgressBarComponent,
    PatientsComponent,
    PatientsSearchComponent,
    PatientEnrollmentsComponent,
    PatientEnrollmentsSearchComponent,
    PatientEnrollmentErrorsComponent,
    PatientFacilitiesVisitComponent,
    PatientInvitationDetailInformationComponent,
    ProgressBarComponent,
    BarGroupFacilitiesComponent,
    BarGroupsComponent,
    BarGroupsCreateUpdateComponent,
    SynonymsComponent,
    UserAppointmentsComponent,
    UsersAndPatientsComponent,
    UserPatientDependentsDetailComponent,
    UpdateUsernameComponent,
    UserPropertiesComponent,
    ScoreComponent,
    ResetOptionsDialogComponent,
    LoginLoaderComponent,
    ReplayEngineComponent,
    EventsMonitoringComponent,

    // Pipes
    ActivePipe,
    CapitalizePipe,
    EnumKeyValueListPipe,
    FacilityAddressPipe,
    SafeHtmlPipe,
    UtcDatePipe,
    PhoneNumberPipe,
    FilterUserLevelPipe
  ],
  imports: [
    // Angular    
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    //Shared
    AppSharedModule,

    // Misc
    AngularSplitModule.forRoot(),

    // Angular Material
    MatLegacyFormFieldModule,
    MatLegacyAutocompleteModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    MatLegacyInputModule,
    MatLegacyCheckboxModule,
    MatLegacyChipsModule,
    MatDatepickerModule,
    MatLegacyDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatLegacyListModule,
    MatLegacyMenuModule,
    MatNativeDateModule,
    MatLegacyPaginatorModule,
    MatLegacyProgressBarModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyRadioModule,
    MatLegacySelectModule,
    MatSidenavModule,
    MatLegacySlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatLegacyTableModule,
    MatLegacyCheckboxModule,
    MatLegacyTabsModule,
    MatToolbarModule,
    MatLegacyTooltipModule,
    // ng-bootstrap
    //NgbModule.forRoot(),
    NgbModule,
    // ngrx
    StoreModule.forRoot({
      info,
      user,
      facility,
      terms,
      parentUser,
      userSearch
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 5
    }),

    // Routing
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'entities/bargroup-facilities', component: BarGroupFacilitiesComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.entities.bargroupFacilities.create } },
      { path: 'entities/facilities', component: FacilitiesComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.entities.facilities } },
      { path: 'support-log-viewer', component: SupportLogViewerComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.search } },
      { path: 'support-log-viewer/view/:username', component: SupportLogViewerComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.search } },
      { path: 'users-dignity-health', component: UsersAndPatientsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.search } },
      { path: 'users-patient-portals', component: UsersAndPatientsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.patientportals.view } },
      { path: 'users-cerner-millennium', component: UsersAndPatientsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.cernermillenium.list } },
      { path: 'users-consumer-identity', component: IdentityApiUsersComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.identity.list } },
      { path: 'login-loader', component: LoginLoaderComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.search } },
      { path: 'users/view', component: UserDetailsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.users.view } },
      { path: 'cerner-invitations', component: CernerInvitationsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.patientPortals.cerner.invitations.list } },
      { path: 'patient-invitation-detail-info', component: PatientInvitationDetailInformationComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.patientPortals.cerner.invitations.list } },
      { path: 'entities/bargroups', component: BarGroupsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.entities.bargroup } },
      { path: 'entities/regions', component: RegionsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.entities.region } },
      { path: 'identity-audit-logs', component: IdentityAuditLogsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.identity.list } },
      { path: 'instructions', component: InstructionsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.instructions.create } },
      { path: 'entities/empi-hubs', component: EmpiHubComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.entities.empihub } },
      { path: 'patient/enrollments', component: PatientEnrollmentsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.enrollments.list } },
      { path: 'patient/enrollment-errors/:id', component: PatientEnrollmentErrorsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.logs.enrollments.view } },
      { path: 'settings/domain-whitelisting', component: DomainWhitelistingComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.siteSettings.domainWhitelisting.create } },
      { path: 'facilitiesVisit/:id', component: PatientFacilitiesVisitComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.facilitiesVisit.view } },
      { path: 'alerts', component: AlertSettingsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.siteSettings.alerts.create } },
      { path: 'cernerSSODashboard', component: CernerEnvironmentConfigsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.developersOptions.cernerSSODashboard.view } },
      { path: 'providersearch/synonyms', component: SynonymsComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.azureSearch.providerSearch.view } },
      { path: 'providersearch/scores', component: ScoreComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.azureSearch.scoreAndFields.create } },
      { path: 'enterprise-events/events-monitoring', component: EventsMonitoringComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.enterpriseEvents.monitoring.view } },
      { path: 'enterprise-events/replay-engine', component: ReplayEngineComponent, canActivate: [AuthGuard, FlagGuard], data: { flag: flags.navigation.enterpriseEvents.replayEngine.view } },
      { path: 'route-disabled', component: RouteDisabledComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '', redirectTo: defaultRoute(), pathMatch: 'full' },
      { path: '**', component: NotFoundComponent }
    ],
      { enableTracing: false }),
    // below code is to read the configuration from httpLoaderFactory will take care in future.
    // AuthModule.forRoot({
    //   loader: {
    //     provide: StsConfigLoader,
    //     useFactory: httpLoaderFactory,
    //     deps: [HttpClient],
    //   }
    // }),
    AuthModule.forRoot({
      config: {
        authority: `${environment.ciamOktaUrl}/oauth2/${environment.idpId}`,
        redirectUrl: `${window.location.origin}`,
        clientId: environment.ciamOktaClientId,
        scope: 'openid profile email groups',
        responseType: 'code',
        triggerAuthorizationResultEvent: true,
        postLogoutRedirectUri: `${window.location.origin}`,
        renewTimeBeforeTokenExpiresInSeconds: environment.renewTimeBeforeTokenExpiresInSeconds,
        startCheckSession: false,
        silentRenew: true,
        silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
        postLoginRoute: '/home',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        logLevel: LogLevel.Debug,
        historyCleanupOff: true,
        useRefreshToken: false,
        customParamsAuthRequest: {
          brand: environment.oktaLoginScreenBrand,
          display_env: displayEnvironmentName(environment.envName)
        }
      },
    })
  ],
  entryComponents: [
    AlertSettingUpdateComponent,
    ExpireModalComponent,
    EmpiHubCreateUpdateComponent,
    ResendModalComponent,
    ResetUserPasswordStatusComponent,
    ResetUserPasswordDialogComponent,
    TemporaryUserPasswordStatusComponent,
    TemporaryUserPasswordDialogComponent,
    UiServiceErrorsDialogComponent,
    RegionsDetailsComponent,
    BarGroupsCreateUpdateComponent,
    UpdateUsernameComponent,
    ResetOptionsDialogComponent,
  ],
  providers: [
    AuthGuard,
    FlagGuard,
    DatePipe,
    BarGroupService,
    FacilitiesService,
    CheckUserAuthService,
    ThemesService,
    SharedSearchService,
    InstructionService,
    PortalLogViewerService,
    IdentityService,
    PatientPortalsInvitationDataSource,
    SiteSettingService,
    TermsAndConditionsDataSource,
    OktaSupportGroupsService,
    AzureSearchService,
    ScoreAndFieldsService,
    UiService,
    OktaApiService,
    HttpErrorHandler,
    UsersDataSource,
    {
      provide: CiamAuth,
      useFactory: ciamAuthFactory
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    PortalAccessService,
    CernerSSOConfigService,
    OidcSecurityService,
    ServerSentEventMonitoringService
  ],
  exports: [AuthModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
