import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventHubStatus } from 'app/types/enterprise-events-models/event-hub-status.model';
/**
* Server-Sent Events service
*/
@Injectable({
    providedIn: 'root', // Ensures singleton service
})
export class ServerSentEventMonitoringService {
    private eventSource: EventSource | null = null;
    private eventSubject = new Subject<any>();
    enterpriseEvents: EventHubStatus[] = [];

    constructor(private zone: NgZone) { }

    getEventSource(url: string, options: EventSourceInit): EventSource {
        return new EventSource(url, options);
    }

    

    connectToServerSentEvents(url: string, options: EventSourceInit, eventNames: string[] = []): Observable<any> {
        this.closeConnection(); // Close existing connection if any
        this.eventSource = new EventSource(url, options);
        this.eventSource.onerror = (error) => {
            this.zone.run(() => this.eventSubject.error(new Error(`SSE Error: ${error}`)));
        };

        eventNames.forEach(event => {
            this.eventSource!.addEventListener(event, (data: MessageEvent) => {
                const eventData = JSON.parse(data.data);
                this.zone.run(() => this.eventSubject.next(eventData));
            });
        });

        return this.eventSubject.asObservable(); // Return the observable for subscription
    }

    //  To close the connection
    closeConnection() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null; // Clear the reference
        }
    }

    setSseDataToList(eventData: EventHubStatus) {
        this.enterpriseEvents.push(eventData);
    }

    getSseDataFromList(): EventHubStatus[] {
        return this.enterpriseEvents;
    }

    removeSseDataFromList() {
        this.enterpriseEvents.pop();
    }
}

