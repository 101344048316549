import { EventActivityItem } from "./event-activity-item.model";
import { ActivityRetentionBucket } from "./activity-retention-bucket.model";

export class EventSubjectActivityStatus {
  eventSubject: string;
  subjectActivity: EventActivityItem[] = [];
  retentionBuckets: ActivityRetentionBucket[];

  constructor(subject: string) {
    this.eventSubject = subject;
    this.retentionBuckets = [
      new ActivityRetentionBucket(1 * 60 * 1000, false), // 1 hour //1 min
      new ActivityRetentionBucket(3 * 60 * 1000, false), // 3 hours //2 min
      new ActivityRetentionBucket(5 * 60 * 1000, true),  // 24 hours //3min
    ];
  }
}
